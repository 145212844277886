<template>
  <div>
    <p class="uppercase tracking-wider text-xl mb-2">Pengenalan Singkat</p>
    <p class="uppercase tracking-widest text-xs mb-2">
      Pembaruan Terakhir:
      {{ lastUpdated | indonesian_datetime_format }}
    </p>
    <toast-editor
      class="mb-2"
      preview-style="tab"
      ref="toastuiEditor"
      initial-value=""
      :height="editor.height"
      :options="editor.options"
      @change="onEditorChange"
    ></toast-editor>
    <button class="button --primary" @click="triggerStoreIntroduction()">
      <fa-icon icon="save"></fa-icon>
      <span class="ml-2">{{
        isProcessing ? "dalam proses..." : "Simpan"
      }}</span>
    </button>
  </div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";

import { Editor } from "@toast-ui/vue-editor";
import _debounce from "lodash/debounce";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      lastUpdated: null,
      isProcessing: false,
      form: {
        content: "",
      },
      editor: {
        options: {
          usageStatistics: false,
          hideModeSwitch: true,
        },
        height: "500px",
      },
    };
  },
  methods: {
    getContent() {
      return this.$refs.toastuiEditor.invoke("getMarkdown");
    },
    setContent(content) {
      return this.$refs.toastuiEditor.invoke("setMarkdown", content);
    },
    onEditorChange: _debounce(
      function () {
        this.form.content = this.getContent();
      },
      700,
      { maxWait: 1000 }
    ),
    async triggerStoreIntroduction() {
      if (this.isProcessing) {
        return false;
      }
      this.isProcessing = true;
      await this.storeIntroduction(this.form);
      this.isProcessing = false;
      this.lastUpdated = this.introduction.updated_at;
    },

    ...mapActions("school_profile", ["fetchIntroduction", "storeIntroduction"]),
  },
  computed: {
    ...mapGetters("school_profile", ["introduction"]),
  },
  async created() {
    await this.fetchIntroduction();
    this.setContent(this.introduction.content);
    this.lastUpdated = this.introduction.updated_at;
  },
  beforeDestroy() {
    this.$refs.toastuiEditor.invoke("destroy");
  },
  components: {
    toastEditor: Editor,
  },
  name: "ShortIntroduction",
};
</script>